import { Store } from "@/store";
import { useFirebase, useStripe, productsAPI } from "@/composables";
import { logError } from "@/utils";
import { isEqual } from "lodash";

class CollectionStore extends Store {
  constructor(storeName) {
    super(storeName, CollectionStore.initialState());
  }
  
  static initialState() {
    return {
      priceGroups: {},
      stripePrices: {},
      products: [],
    };
  }

  async initialize() {
    try {
      await Promise.all([
        this.fetchPriceGroups(),
        this.fetchStripePrices(),
        this.fetchProducts(),
      ])
    } catch(e) {
      logError(`collectionStore.initialize: Failed to initialize collectionStore. ${e.toString()}`)
    }
  }

  get priceGroups () {
    try {
      if (isEqual(this.state.priceGroups,{})) {
        this.fetchPriceGroups();
      }
      return this.state.priceGroups;
    } catch (e) {
      logError(`collectionStore.getPriceGroups: Failed to get priceGroups. ${e.toString()}`)
    }
    return {}
  }

  async fetchPriceGroups() {
    try {
      if (isEqual(this.state.priceGroups,{})) {
        this.state.priceGroups = await useFirebase().getPriceGroups()
      }
    } catch (e) {
      logError(`collectionStore.fetchPriceGroups: Failed to retrieve price groups. ${e.toString()}`);
    }
  }

  get stripePrices () {
    try {
      if (isEqual(this.state.priceGroups,{})) {
        this.fetchStripePrices();
      }
      return this.state.stripePrices;
    } catch (e) {
      logError(`collectionStore.getStripePrices: Failed to get stripePrices. ${e.toString()}`)
    }
    return {}
  }

  async fetchStripePrices() {
    try {
      if (isEqual(this.state.stripePrices,{})) {
        this.state.stripePrices = await useStripe().getStripePrices();
      }
    } catch (e) {
      logError(`collectionStore.fetchStripePrices: Failed to fetch stripe prices. ${e.toString()}`);
    }
  }

  get products () {
    try {
      if (this.state.products.length == 0) {
        this.fetchProducts();
      }
      return this.state.products;
    } catch (e) {
      logError(`collectionStore.getProducts: Failed to get products. ${e.toString()}`)
    }
    return []
  }

  async fetchProducts() {
    try {
      if (this.state.products.length == 0)
        this.state.products = await productsAPI.getProducts({
          active: { op: "eq", v: true },
          posted: { op: "eq", v: true },
          type: { op: "ne", v: "supply" },
        });
    } catch (e) {
      logError(`collectionStore.fetchProducts: Failed to fetch products. ${e.toString()}`)
    }
  }
}

export const collectionStore = new CollectionStore("collection-store");
