<template>
  <Loading v-if="loading" />
  <template v-else>
    <v-banner color="success" :stacked="false" dismissible v-if="showBanner">
      <v-banner-text style="font-weight: 800; font-size: 16px">
        {{ noticeText }}
      </v-banner-text>

      <template v-slot:actions>
        <v-btn @click="dismissBanner">Dismiss</v-btn>
      </template>
    </v-banner>
    <div v-if="!sidebarDisabled" :style="{ 'margin-left': sidebarWidth, transition: '0.3s ease-in-out' }" />
    <div v-if="!sidebarDisabled" :style="{ 'margin-left': sidebarWidth, transition: '0.3s ease-in-out' }">
      <Sidebar />
      <router-view v-if="!loading" />
    </div>
    <div v-else>
      <router-view v-if="!loading" />
    </div>
  </template>
</template>

<script setup>
// Library Components
import { watch, ref, onMounted, reactive, computed } from "vue";
// Library Composables
import { useRouter } from "vue-router";
import { useFirebase } from "@/composables";

// Custom Components
import { Sidebar, Loading } from "@/components";
import { sidebarWidth } from "@/components/sidebarFolder/state";
// Custom Composables
// State Management
import { userStore } from "@/store";
// Utils
import { logError } from "@/utils";

const loading = ref(true);

const sidebarDisabled = ref(true);

const notes = reactive({});
const showBanner = ref(false);

const languagePreference = ref("");

const router = useRouter();
const _fb = useFirebase();

onMounted(async () => {
  try {
    await userStore.initialize();

    checkRoute();
    if (userStore.fb_user == null) {
      loading.value = false;
      return;
    }

    languagePreference.value = userStore.languagePreference;
    notes.value = await _fb.getNotes();

    if (userStore.placeID.length != 0 && notes.value && notes.value.notice && notes.value.notice.show) {
      showBanner.value = true;
      setTimeout(() => {
        if (!notes.value.notice?.en && !notes.value.notice?.fr) {
          dismissBanner();
        }
      }, 5000);
    }
  } catch (err) {
    logError(`app.onMounted: Failed to set up component. ${err.toString()}`);
  }
  loading.value = false;
});

const noticeText = computed(() => {
  if (!notes.value.notice) return "Loading...";
  if (languagePreference.value == "fr") {
    return notes.value.notice.fr;
  } else {
    return notes.value.notice.en;
  }
});

watch(router.currentRoute, () => {
  checkRoute();
});

function checkRoute() {
  let path = router.currentRoute.value.name;
  if (
    path === "Login" ||
    path === "Register" ||
    path === "BusinessRegistration" ||
    path === "BusinessPending" ||
    path === "ForgotPassword" ||
    path === "Home"
  )
    sidebarDisabled.value = true;
  else sidebarDisabled.value = false;

  if (!userStore.fb_user && router.currentRoute.value.name == "ForgotPassword") {
    router.push({ name: "ForgotPassword" });
  } else if (!userStore.fb_user && router.currentRoute.value.name == "Register") {
    router.push({ name: "Register" });
  } else if (!userStore.fb_user && router.currentRoute.value.name != "Login") {
    router.push({ name: "Login" });
  } else if (userStore.placeID.length != 0 && userStore.portalApproved && router.currentRoute.value.name == "BusinessPending") {
    router.push({ name: "Order" });
  } else if (userStore.placeID.length != 0 && router.currentRoute.value.name == "Home") {
    router.push({ name: "BusinessPending" });
  } else if (userStore.placeID.length == 0 && router.currentRoute.value.name != "Home") {
    router.push({ name: "Order" });
  }
}

function dismissBanner() {
  showBanner.value = false;
}
</script>

<style lang="scss" scoped>
.v-banner {
  margin-left: 180px;
  padding-top: 15px;

  width: calc(100% - 180px);
  z-index: 100;
  border-width: thin;
  color: black; /* Set text color to black */
}
</style>
