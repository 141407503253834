import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { logError } from "@/utils";

import { Outlet } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'cyclesReport',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
  padding: {
    type: String,
    default: "",
    required: false,
  },
},
  setup(__props) {

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const cycleTime: Ref<number> = ref(0);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("cyclesReport: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let cyclesReport = await props.outlet!.getCyclesReport(props.weeks, props.group);
    cycleTime.value = cyclesReport;
  } catch (err: any) {
    failed.value = true;
    logError(`usersReport: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataPanel), {
    type: "cycle-time",
    id: "cycle-time",
    fontColour: "ocean",
    bgColour: "transparent",
    data: [
      {
        value: { time: cycleTime.value },
        title: { text: 'Cycle Time', colour: 'denim' },
      },
    ],
    unit: _ctx.$t('analytics.days'),
    loading: loading.value,
    failed: failed.value,
    padding: props.padding
  }, null, 8, ["data", "unit", "loading", "failed", "padding"]))
}
}

})