<template>
  <template v-if="!loading && !failed">
    <div v-if="ordersBreakdown.keys && ordersBreakdown.keys.size > 0" class="table-container">
      <div class="table">
        <table>
          <thead>
            <tr>
              <th>Week</th>
              <td v-for="key of ordersBreakdown.keys" :key="key">
                {{ key }}
              </td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="value of ordersBreakdown.values" :key="value.week">
              <th>{{ value.week }}</th>
              <td v-for="key of ordersBreakdown.keys" :key="`${value.week}-${key}`">
                {{ key in value ? value[key] : 0 }}
              </td>
            </tr>
            <tr>
              <th></th>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-else class="no-orders">No Orders made within time period</div>
  </template>
  <template v-else-if="loading && !failed">
    <span class="loading">
      <Loading />
    </span>
  </template>
  <template v-else-if="!loading && failed">
    <span class="failed">
      <span class="danger-icon"><img :src="DangerIconBlack" /></span>
      <p class="is-cloud">Failed to Load</p>
    </span>
  </template>
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { FriendlierLineChart, Loading } from "@/components";
import { logError } from "@/utils";
import { DangerIconBlack } from "@/assets";

import { Outlet, Product } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const ordersBreakdown: Ref<{ [key: string]: any }> = ref({});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("ordersBreakdown: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    ordersBreakdown.value = await props.outlet!.getOrdersBreakdown(props.weeks, props.group);
  } catch (err: any) {
    failed.value = true;
    logError(`OutletReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.no-orders {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: $denim;
}

.title {
  height: 50px;
  width: 100%;
  padding: 0.75rem;
  padding-bottom: 1%;
  color: $denim;
  font-size: 1.5rem;
  font-weight: 400;
}

.table-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .table {
    overflow: auto;
    height: 100%;
    max-height: 350px;
    width: 100%;

    table {
      white-space: nowrap;
      margin: 0;
      border: none;
      border-collapse: separate;
      border-spacing: 0;
      table-layout: fixed;
      width: 100%;

      thead {
        background-color: white;

        tr {
          padding: 2px;
          position: sticky;
          top: 0;
          z-index: 1;
          min-width: 100px;
          text-align: center;

          th,
          td {
            text-align: center;
            background: white;
            border-radius: 15px;
            padding: 7px 12px;
            border: 2px solid $denim;
          }
        }

        :first-child {
          position: sticky;
          left: 0;
          z-index: 2;
        }
      }
      tbody {
        tr {
          border-bottom: 1px solid $denim;
          td {
            background: white;
            padding: 7px 12px;
            text-align: center;
          }
          th {
            font-weight: 900;
            font-style: italic;
            text-align: center;

            position: sticky;
            left: 0;
            background: white;
            z-index: 1;
          }
        }
      }
    }
  }
}
</style>
