import { productsAPI } from "@/composables";
import { User } from "@/models";
import { logError } from "@/utils";

abstract class ProductModel {
  _id: string | undefined;
  _boxIdTag: string | undefined;
  _boxIDTag: string | undefined;

  /*** Details ***/
  _containersPerCase: number | undefined;
  _minQuantity: number | undefined;
  _size: string | undefined;
  _name: string | undefined;
  _type: string | undefined;
  _memo: string | undefined;
  _imageURL: string | undefined;
  _weight: number | undefined;

  _posted: boolean = false;
  _active: boolean = false;
  _deleted: boolean = false;

  /*** Pricing Details ***/
  // stripeProductIDDeposit
  // stripeProductIDUnit
  // stripePricesDeposit
  // stripePricesUnit
}

class ProductController extends ProductModel {
  static async filter(
    filters: { [key: string]: any } = {},
    fields: string[] | undefined = undefined
  ): Promise<Product[] | { [key: string]: any }[]> {
    let products: Product[] | { [key: string]: any }[] = [];
    try {
      let results = await productsAPI.getProducts(filters, fields);

      for (let result of results) {
        if (fields) {
          products.push(result);
        } else {
          products.push(new Product().setup(result));
        }
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get product ${err.toString()}`);
    }
    return products;
  }

  private setup(data: { [key: string]: any }) {
    try {
      for (let key in data) {
        if (key in this) this[key as keyof typeof this] = data[key];
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get product ${err.toString()}`);
    }
    return this;
  }

  get id(): string | undefined {
    return this._id;
  }
  set id(value: string | undefined) {
    this._id = value;
  }

  get boxIdTag(): string | undefined {
    return this._boxIdTag;
  }
  set boxIdTag(value: string | undefined) {
    this._boxIdTag = value;
  }

  get boxIDTag(): string | undefined {
    return this._boxIDTag;
  }
  set boxIDTag(value: string | undefined) {
    this._boxIDTag = value;
  }

  get containersPerCase(): number | undefined {
    return this._containersPerCase;
  }
  set containersPerCase(value: number | undefined) {
    this._containersPerCase = value;
  }

  get minQuantity(): number | undefined {
    return this._minQuantity;
  }
  set minQuantity(value: number | undefined) {
    this._minQuantity = value;
  }

  get size(): string | undefined {
    return this._size;
  }
  set size(value: string | undefined) {
    this._size = value;
  }

  get name(): string | undefined {
    return this._name;
  }
  set name(value: string | undefined) {
    this._name = value;
  }

  get type(): string | undefined {
    return this._type;
  }
  set type(value: string | undefined) {
    this._type = value;
  }

  get memo(): string | undefined {
    return this._memo;
  }
  set memo(value: string | undefined) {
    this._memo = value;
  }

  get imageURL(): string | undefined {
    return this._imageURL;
  }
  set imageURL(value: string | undefined) {
    this._imageURL = value;
  }

  get weight(): number | undefined {
    return this._weight;
  }
  set weight(value: number | undefined) {
    this._weight = value;
  }

  get posted(): boolean {
    return this._posted;
  }
  set posted(value: boolean | undefined) {
    this._posted = value ?? false;
  }

  get active(): boolean {
    return this._active;
  }
  set active(value: boolean | undefined) {
    this._active = value ?? false;
  }

  get deleted(): boolean {
    return this._deleted;
  }
  set deleted(value: boolean | undefined) {
    this._deleted = value ?? false;
  }
}

class Product extends ProductController {
  static async getActiveProducts(): Promise<Product[]> {
    try {
      return (await this.filter({
        active: { op: "eq", v: true },
        posted: { op: "eq", v: true },
        type: { op: "ne", v: "supply" },
      })) as Product[];
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }
}

export default Product;
