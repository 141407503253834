import { logError } from '@/utils';
import { useAPI } from '@/composables';

async function getFullPermissions(tier) {
  try {
    const response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getPermissions`,
      'POST',
      { tier },
      {
        includeAuth: true,
        includeToken: true,
      }
    );

    return response.data;
  } catch (err) {
    console.log(err);
    logError(`permissionsAPI.getFullPermissions: Failed to get permissions. ${err.toString()}`);
    throw new Error(`Failed to get permissions ${err.toString()}`);
  }
}

const permissionsAPI = {
  getFullPermissions,
}

export default permissionsAPI;