<template>
  <DataPanel
    type="return-rate"
    id="ror"
    fontColour="ocean"
    bgColour="transparent"
    :data="[
      {
        value: {
          percent: rate,
          returned: returns,
          sold: sold,
        },
        title: {
          text: $t('analytics.return-rate'),
          colour: 'denim',
        },
      },
    ]"
    unit="%"
    :loading="loading"
    :failed="failed"
  />
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { logError, getBasicStats } from "@/utils";

import { Outlet, Product } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const returns: Ref<number> = ref(0);
const sold: Ref<number> = ref(0);
const rate: Ref<number> = ref(0);

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("ReturnRates: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let products: Product[] = await Product.getActiveProducts();
    let productMap: { [key: string]: number } = {};
    for (let product of products) {
      productMap[product.boxIDTag!] = product.containersPerCase!;
    }
    let returnRates = await props.outlet!.getReturnRates(props.weeks, props.group, productMap);
    returns.value = returnRates["returns"];
    sold.value = returnRates["sold"];

    let divZero = sold.value == 0 ? 1 : sold.value;
    rate.value = Math.round((returns.value / divZero) * 100);
  } catch (err: any) {
    console.log(err);
    failed.value = true;
    logError(`ReturnRates: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.data-delim {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  margin: auto;
  height: fit-content;

  .divider {
    flex-grow: 2;
    background-color: $cloud;
    min-height: 20px;
    min-width: 1px;
  }

  .text {
    color: $cloud;
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .data-delim {
    display: none;
  }
}
</style>
