import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { logError } from "@/utils";

import { Outlet } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'usersReport',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
  padding: {
    type: String,
    default: "",
    required: false,
  },
},
  setup(__props) {

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const active: Ref<number> = ref(0);
const repeat: Ref<number> = ref(0);
const newUsers: Ref<number> = ref(0);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("usersReport: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let usersReport = await props.outlet!.getUsersReport(props.weeks, props.group);
    active.value = usersReport.active;
    repeat.value = usersReport.repeat;
    newUsers.value = usersReport.new;
  } catch (err: any) {
    failed.value = true;
    logError(`usersReport: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataPanel), {
    type: "user-report",
    id: "active-users",
    fontColour: "ocean",
    bgColour: "transparent",
    data: [
      {
        value: {
          total: active.value,
          repeat: repeat.value,
          new: newUsers.value,
        },
        title: { text: 'Active Users', colour: 'denim' },
      },
    ],
    loading: loading.value,
    failed: failed.value,
    padding: props.padding
  }, null, 8, ["data", "loading", "failed", "padding"]))
}
}

})