import { outletsAPI, useAnalytics } from "@/composables";
import { User, Product } from "@/models";
import { logError } from "@/utils";

abstract class OutletModel {
  _placeID!: string;
  _companyName!: string;
  _displayName: string | undefined;
  _returnedFrom: number | undefined;
  _outletGroup: string | undefined;
  _sold: number | undefined;
  _activeUsers: number | undefined;
  _repeatUsers: number | undefined;
  _newUsers: number | undefined;
  _cycleTime: number | undefined;
  _groupReturns: { [key: string]: any } | undefined;
}

class OutletController extends OutletModel {
  /**
   * Filters the outlet based on the provided place ID and optional fields.
   *
   * @param placeID - The place ID of the outlet.
   * @param fields - Optional fields to include in the filtered outlet.
   * @returns The filtered outlet.
   * @throws Error if failed to get the outlet.
   */
  static async filter(
    filters: { [key: string]: any } = {},
    fields: string[] | undefined = undefined
  ): Promise<Outlet[] | { [key: string]: any }[]> {
    let outlets: Outlet[] | { [key: string]: any }[] = [];
    try {
      let results = await outletsAPI.getOutlets(filters, fields);

      for (let result of results) {
        if (fields) {
          outlets.push(result);
        } else {
          outlets.push(new Outlet().setup(result));
        }
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
    return outlets;
  }

  async getStats(
    weeks: string[] = [],
    group: boolean = false
  ): Promise<{ [key: string]: any }> {
    try {
      let api = useAnalytics();
      let stats = await api.getOutletAnalytics(this.placeID, weeks, group);
      return stats;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  /**
   * Sets up the outlet with the provided data.
   *
   * @param data - The data object containing the properties to set.
   * @returns The updated outlet instance.
   * @throws Error if there is a failure in setting up the outlet.
   */
  private setup(data: { [key: string]: any }) {
    try {
      for (let key in data) {
        if (key in this) this[key as keyof typeof this] = data[key];
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
    return this;
  }

  get placeID(): string {
    return this._placeID;
  }
  set placeID(value: string) {
    this._placeID = value;
  }

  get companyName() {
    return this._companyName ?? "Unknown";
  }
  set companyName(value: string) {
    this._companyName = value;
  }

  get displayName() {
    return this._displayName ?? this._companyName;
  }
  set displayName(value: string | undefined) {
    this._displayName = value;
  }

  get returnedFrom() {
    return this._returnedFrom ?? 0;
  }
  set returnedFrom(value: number | undefined) {
    this._returnedFrom = value ?? 0;
  }

  get sold() {
    return this._sold ?? 0;
  }
  set sold(value: number | undefined) {
    this._sold = value ?? 0;
  }

  get activeUsers() {
    return this._activeUsers ?? 0;
  }
  set activeUsers(value: number | undefined) {
    this._activeUsers = value ?? 0;
  }

  get repeatUsers() {
    return this._repeatUsers ?? 0;
  }
  set repeatUsers(value: number | undefined) {
    this._repeatUsers = value ?? 0;
  }

  get newUsers() {
    return this._newUsers ?? 0;
  }
  set newUsers(value: number | undefined) {
    this._newUsers = value ?? 0;
  }

  get cycleTime() {
    return this._cycleTime ?? 0;
  }
  set cycleTime(value: number | undefined) {
    this._cycleTime = value ?? 0;
  }

  get groupReturns() {
    return this._groupReturns ?? {};
  }
  set groupReturns(value: { [key: string]: any } | undefined) {
    this._groupReturns = value ?? {};
  }

  get outletGroup() {
    return this._outletGroup ?? undefined;
  }
  set outletGroup(value: string | undefined) {
    this._outletGroup = value;
  }
}

class Outlet extends OutletController {
  /**
   * Retrieves an outlet based on the provided place ID.
   *
   * @param placeID - The place ID of the outlet.
   * @returns A promise that resolves to the outlet.
   * @throws If there is an error retrieving the outlet.
   */
  static async getOutlet(placeID: string) {
    try {
      return (await this.filter({ placeID: { op: "eq", v: placeID } }))[0];
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  /**
   * Retrieves outlet data based on the provided place ID and fields.
   * @param placeID - The ID of the place.
   * @param fields - Optional array of fields to retrieve. If not provided, all fields will be retrieved.
   * @returns A promise that resolves to the outlet data.
   * @throws An error if the outlet data retrieval fails.
   */
  static async getOutletData(
    placeID: string,
    fields: string[] | undefined = undefined
  ) {
    try {
      let temp = await OutletController.filter(
        { placeID: { op: "eq", v: placeID } },
        fields
      );
      return temp;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  /**
   * Retrieves the outlets associated with a user.
   *
   * @param user - The user object.
   * @returns An array of outlets.
   * @throws If the user has no outlet or if there is an error retrieving the outlets.
   */
  static async getUserOutlets(user: User) {
    try {
      let placeID = user.placeID;
      if (placeID.length == 0) throw new Error("User has no outlet");

      let outlets: Outlet[] = [];
      while (placeID.length > 0) {
        let places = placeID.splice(0, 10);
        outlets.push(
          ...((await this.filter({
            placeID: { op: "in", v: places },
          })) as Outlet[])
        );
      }

      return outlets;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  /**
   * Retrieves the total returns for the outlet associated with the current instance.
   *
   * @throws {Error} Throws an error if the retrieval of total returns fails.
   */
  async getBasicStats(
    weeks: string[] = [],
    includeGroup: boolean = false
  ): Promise<number> {
    try {
      let returns = 0;
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week of entry) {
            returns += week.total_returns;
          }
        }
      } else {
        let outlet = await OutletController.filter(
          { placeID: { op: "eq", v: this.placeID } },
          ["returnedFrom"]
        );
        returns = outlet[0].returnedFrom;
      }
      this.returnedFrom = returns;
      return this.returnedFrom ?? 0;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  /**
   * Retrieves the analytic entries for the outlet.
   * @returns {Promise<string[]>} A promise that resolves to the analytic entries.
   * @throws {Error} If there is an error retrieving the analytic entries.
   */
  async getAnalyticEntries(): Promise<string[]> {
    try {
      let api = useAnalytics();
      let entries = await api.getAnalyticEntries(this.placeID);
      return entries.analytic_entries;
    } catch (err: any) {
      logError(
        `Failed to get analytic entries for outlet ${
          this.placeID
        }. ${err.toString()}`
      );
      throw new Error(
        `Failed to get analytic entries for outlet ${this.placeID}`
      );
    }
  }

  async getReturnRates(
    weeks: string[] = [],
    includeGroup: boolean = false,
    products: { [key: string]: number }
  ): Promise<{ [key: string]: number }> {
    try {
      let returnRates: { [key: string]: number } = {};
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        returnRates["returns"] = 0;
        returnRates["sold"] = 0;

        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week of entry) {
            if (
              week.sold_override != undefined &&
              week.sold_override != null &&
              week.sold_override != 0
            ) {
              returnRates["sold"] += week.sold_override;
            } else {
              for (let box in week.boxes_opened) {
                returnRates["sold"] += products[box] * week.boxes_opened[box];
              }
            }
            returnRates["returns"] += week.total_returns;
          }
        }
      } else {
        let outlet = await OutletController.filter(
          { placeID: { op: "eq", v: this.placeID } },
          ["sold", "returnedFrom"]
        );
        returnRates["sold"] = outlet[0].sold;
        returnRates["returns"] = outlet[0].returnedFrom;
      }
      this.returnedFrom = returnRates["returns"];
      this.sold = returnRates["sold"];
      return returnRates;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get outlet ${err.toString()}`);
    }
  }

  async getUsersReport(
    weeks: string[] = [],
    includeGroup: boolean = false
  ): Promise<{ [key: string]: any }> {
    try {
      let usersReport: { [key: string]: number } = {
        active: 0,
        repeat: 0,
        new: 0,
      };

      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        let totalOutlets = 0;

        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;

          let entry: { [key: string]: any }[] = stats[outlet];
          let totalActive = 0;
          let totalRepeat = 0;
          let totalNew = 0;
          let numberOfWeeksForOutlet = entry.length;

          for (let week of entry) {
            totalActive += week.existing_users + week.new_users;
            totalRepeat += week.existing_users;
            totalNew += week.new_users;
          }

          // Calculate the average per week for this outlet
          if (numberOfWeeksForOutlet > 0) {
            usersReport["active"] += totalActive / numberOfWeeksForOutlet;
            usersReport["repeat"] += totalRepeat / numberOfWeeksForOutlet;
            usersReport["new"] += totalNew / numberOfWeeksForOutlet;
          }

          totalOutlets++;
        }
        // console.log("Given Stats:", stats);
        // console.log("Given Outlets:", totalOutlets);

        // Divide overall total by number of outlets
        if (totalOutlets > 0) {
          usersReport["active"] /= totalOutlets;
          usersReport["repeat"] /= totalOutlets;
          usersReport["new"] /= totalOutlets;
        }
      }
      this.activeUsers = usersReport["active"];
      this.repeatUsers = usersReport["repeat"];
      this.newUsers = usersReport["new"];
      return usersReport;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get usersReport ${err.toString()}`);
    }
  }

  async getCyclesReport(
    weeks: string[] = [],
    includeGroup: boolean = false
  ): Promise<number> {
    try {
      let cyclesReport: number[] = [];
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week of entry) {
            cyclesReport.push(
              week["avgerage_time_between_distribution_and_return"] /
                60 /
                60 /
                24
            );
          }
        }
      }
      let sum = cyclesReport.reduce((a, b) => a + b, 0);
      this.cycleTime = Math.round((sum / cyclesReport.length || 0) * 10) / 10;
      return this.cycleTime;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get cyclesReport ${err.toString()}`);
    }
  }

  async getGroupReturns(weeks: string[]): Promise<{ [key: string]: number }> {
    try {
      let groupReturns: { [key: string]: any } = {};
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, true);

        for (let outlet in stats) {
          if (!(outlet in stats)) continue;
          if (outlet == "total") continue;
          groupReturns[outlet] = { returns: 0, name: "" };
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week in entry) {
            groupReturns[outlet].name = entry[week].name;
            groupReturns[outlet].returns += entry[week].total_returns;
          }
        }
      }
      this.groupReturns = groupReturns;
      return groupReturns;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get group returns ${err.toString()}`);
    }
  }

  async getReturnsBreakdown(
    weeks: string[],
    includeGroup: boolean = false,
    products: { [key: string]: number }
  ): Promise<{ [key: string]: any }> {
    try {
      let returnsBreakdown: { [key: string]: any } = {};
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        returnsBreakdown = { sold: {}, returns: {} };
        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week of entry) {
            const weekStr = week.week.split("T")[0];
            if (returnsBreakdown["returns"][weekStr] == undefined) {
              returnsBreakdown["returns"][weekStr] = 0;
            }
            if (returnsBreakdown["sold"][weekStr] == undefined) {
              returnsBreakdown["sold"][weekStr] = 0;
            }
            returnsBreakdown["returns"][weekStr] += week.total_returns;

            if (
              week.sold_override != undefined &&
              week.sold_override != null &&
              week.sold_override != 0
            ) {
              returnsBreakdown["sold"][weekStr] += week.sold_override;
            } else {
              for (let box in week.boxes_opened) {
                returnsBreakdown["sold"][weekStr] +=
                  products[box] * week.boxes_opened[box];
              }
            }
          }
        }
      }
      return {
        tabs: ["total"],
        datasets: {
          total: {
            Returned: returnsBreakdown["returns"],
            "Sold*": returnsBreakdown["sold"],
          },
        },
      };
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get returns breakdown ${err.toString()}`);
    }
  }

  async getOrdersBreakdown(
    weeks: string[],
    includeGroup: boolean = false
  ): Promise<{ [key: string]: any }> {
    try {
      let breakdown: { [key: string]: any } = { keys: new Set(), values: [] };
      if (weeks.length > 0) {
        let stats = await this.getStats(weeks, includeGroup);

        let ordersBreakdown: { [key: string]: any } = {};
        for (let outlet in stats) {
          if (outlet == "total") continue;
          if (!(outlet in stats)) continue;
          let entry: { [key: string]: any }[] = stats[outlet];
          for (let week of entry) {
            if (!week["order_details"]) continue;
            Object.keys(week["order_details"]).forEach((x) =>
              breakdown["keys"].add(x)
            );

            ordersBreakdown[week.week.split("T")[0]] = {};

            for (let product in week["order_details"]) {
              if (!(product in ordersBreakdown[week.week.split("T")[0]])) {
                ordersBreakdown[week.week.split("T")[0]][product] = 0;
              }
              ordersBreakdown[week.week.split("T")[0]][product] +=
                week["order_details"][product];
            }
          }
        }

        for (let week of Object.keys(ordersBreakdown).sort()) {
          breakdown["values"].push({ week: week, ...ordersBreakdown[week] });
        }
      }
      return breakdown;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get orders breakdown ${err.toString()}`);
    }
  }
}

export default Outlet;
