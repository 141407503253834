<template>
  <FriendlierLineChart
    id="returns-line"
    :labels="['']"
    :dataset="returnsBreakdown"
    defaultKey="total"
    title="Sold* vs Returned"
    :loading="loading"
  />
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { FriendlierLineChart } from "@/components";
import { logError } from "@/utils";

import { Outlet, Product } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const returnsBreakdown: Ref<{ [key: string]: any }> = ref({});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("OutletReturns: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    let products: Product[] = await Product.getActiveProducts();
    let productMap: { [key: string]: number } = {};
    for (let product of products) {
      productMap[product.boxIDTag!] = product.containersPerCase!;
    }

    let test = await props.outlet!.getReturnsBreakdown(props.weeks, props.group, productMap);
    returnsBreakdown.value = test;
  } catch (err: any) {
    failed.value = true;
    logError(`OutletReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss"></style>
