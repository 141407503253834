<template>
  <Loading v-if="loading" />
  <template v-else>
    <NotPermitted v-if="!permitted" />
    <template v-else>
      <div class="page-container">
        <div class="header-container">
          <span>
            <p class="mr-3 is-size-3 is-family-secondary has-text-info">
              {{ $t("pastOrders.title") }}
            </p>
            <Loading v-if="viewLoading" medium />
          </span>
          <ClientSelect />
        </div>

        <div class="t-container">
          <table class="table is-hoverable is-fullwidth has-text-centered">
            <thead>
              <tr>
                <th @click.prevent="() => toggleSort('id')" scope="col" style="text-align: left">
                  {{ $t("pastOrders.order-id") }}
                  <font-awesome-icon
                    v-if="sortCol == 'id'"
                    :icon="['fas', sortDir == 'asc' ? 'arrow-down' : 'arrow-up']"
                    style="margin-left: 10px"
                  />
                  <font-awesome-icon v-else :icon="['fas', 'minus']" style="margin-left: 10px" />
                </th>
                <th @click.prevent="() => toggleSort('company')" scope="col" style="text-align: left">
                  {{ $t("pastOrders.company-name") }}
                  <font-awesome-icon
                    v-if="sortCol == 'company'"
                    :icon="['fas', sortDir == 'asc' ? 'arrow-down' : 'arrow-up']"
                    style="margin-left: 10px"
                  />
                  <font-awesome-icon v-else :icon="['fas', 'minus']" style="margin-left: 10px" />
                </th>
                <th @click.prevent="() => toggleSort('time')" scope="col" style="text-align: left">
                  {{ $t("pastOrders.date-submitted") }}
                  <font-awesome-icon
                    v-if="sortCol == 'time'"
                    :icon="['fas', sortDir == 'asc' ? 'arrow-down' : 'arrow-up']"
                    style="margin-left: 10px"
                  />
                  <font-awesome-icon v-else :icon="['fas', 'minus']" style="margin-left: 10px" />
                </th>
                <th @click.prevent="() => toggleSort('payment')" scope="col" style="text-align: left">
                  {{ $t("pastOrders.payment") }}
                  <font-awesome-icon
                    v-if="sortCol == 'payment'"
                    :icon="['fas', sortDir == 'asc' ? 'arrow-down' : 'arrow-up']"
                    style="margin-left: 10px"
                  />
                  <font-awesome-icon v-else :icon="['fas', 'minus']" style="margin-left: 10px" />
                </th>
                <th scope="col" style="text-align: left">
                  {{ $t("pastOrders.delivery-date") }}
                </th>
                <th scope="col" style="text-align: left">
                  {{ $t("pastOrders.order-details") }}
                </th>
              </tr>
            </thead>
            <tbody @scroll="onScroll">
              <tr v-for="(order, index) in orders" :key="order.orderID">
                <td style="text-align: left; min-width: 20%">{{ order.orderID }}</td>
                <td style="text-align: left; min-width: 20%; overflow-x: elipse">
                  {{ restaurants[order.outlet]?.displayName || "Unknown Outlet" }}
                </td>
                <td class="time" style="text-align: left; min-width: 12%">
                  {{ formatTime(order.timeSubmitted, "both") }}
                  <!-- <div class="col" style="max-width: 38%; width: fit-content">
                    {{ formatTime(order.timeSubmitted, "date") }}
                  </div> -->
                  <!-- <div class="col" style="max-width: 30%">
                    {{ formatTime(order.timeSubmitted, "time") }}
                  </div> -->
                </td>
                <td style="text-align: left; min-width: 12%">
                  <span class="is-flex is-align-items-center is-justify-content-flex-start is-relative">
                    <p class="p-0 m-0 has-text-right">
                      {{ formatPrice(order.payment / 100) }}
                    </p>
                  </span>
                </td>
                <td style="text-align: left; min-width: 12%">
                  {{ formatTime(order.deliveryDate, "both") }}
                </td>
                <td style="text-align: left; min-width: 13%">
                  <button class="button is-info modal-view-button" @click="() => selectOrder(index)">
                    {{ $t("pastOrders.view") }}
                  </button>
                </td>
                <td class="modal-coloumn">
                  <br />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <CheckoutModal
          v-if="activeOrder != -1"
          @close="() => selectOrder(-1)"
          :editable="false"
          :activeOrder="orders[activeOrder]"
          :quantities="orders[activeOrder].orderDetails"
          :activeRestaurant="restaurants[orders[activeOrder].outlet]"
          :products="products"
        />
      </div>

      <Modal
        v-if="paymentSuccessful"
        :title="$t('pastOrders.order-complete')"
        :body="$t('pastOrders.thank-you')"
        :close="() => (paymentSuccessful = false)"
        :actions="[
          {
            text: $t('dismiss'),
            id: 1,
            click: () => {
              paymentSuccessful = false;
            },
          },
          {
            text: $t('input'),
            id: 1,
            click: () => {
              window.location.href = `${process.env.VUE_APP_HOSTING_URL}/feedback`;
            },
          },
        ]"
        :isDanger="false"
      />
    </template>
  </template>
</template>

<script setup>
import { ref, onMounted, watch } from "vue";

import { useRouter } from "vue-router";
import { isEqual } from "lodash";

import { getUser, useFirebase, useStripe, productsAPI, outletsAPI, ordersAPI } from "@/composables";
import { Loading, Modal, CheckoutModal, NotPermitted, ClientSelect } from "@/components";
import { userStore, permissionStore, clientStore } from "@/store";
import { formatPrice, sortOrders, logError } from "@/utils";

const router = useRouter();
const _fb = useFirebase();
const _stripe = useStripe();

const { user } = getUser();

const loading = ref(true);
const permitted = ref(false);

const viewLoading = ref(false);
const allDataLoaded = ref(false);
const lastFetched = ref({});

const products = ref([]);
const restaurants = ref({});
const orders = ref([]);

const activeOrder = ref(-1);

const sortCol = ref("time");
const sortDir = ref("dsc");

// @deprecated states to do with payment flow
const paymentSuccessful = ref(false);
const stripePrices = ref(null);
const priceGroups = ref(null);

const DATA_FETCH_COUNT = 12;

onMounted(async () => {
  document.title = "Friendlier | Past Orders";
  await setupUserData();

  await paymentFlow();
  loading.value = false;

  await getData(DATA_FETCH_COUNT);
  await getData(DATA_FETCH_COUNT);
});

watch(
  () => clientStore.getState().activeClient,
  async () => {
    orders.value = [];
    lastFetched.value = {};
    await getData(DATA_FETCH_COUNT);
    await getData(DATA_FETCH_COUNT);
  }
);

watch(user, () => {
  if (!user.value) {
    router.push({ name: "Login" });
  }
});

function onScroll(e) {
  let target = e.currentTarget;
  if (target.scrollTop + target.clientHeight >= target.scrollHeight && !viewLoading.value && !allDataLoaded.value) {
    getData(DATA_FETCH_COUNT);
  }
}

/**
 * Initializes user data for the PastOrders view.
 *
 * @returns {Promise<void>} A promise that resolves when the user data is successfully initialized.
 */
async function setupUserData() {
  try {
    await userStore.initialize();
    await clientStore.initialize();
    await permissionStore.initialize();

    try {
      if (!userStore.portalApproved) {
        router.push({ path: "/business-pending" });
        return;
      }
    } catch (err) {
      logError(`PastOrders.getData: Failed to check for portal approval. ${err.toString()}`);
      router.push("login");
    }

    try {
      if (permissionStore.can("viewPastOrders")) {
        permitted.value = true;
      } else {
        loading.value = false;
        return;
      }
    } catch (err) {
      logError(`Order.getData: Failed to apply permissions. ${err.toString()}`);
    }

    // Collect Products
    products.value = await productsAPI.getProducts({ active: { op: "eq", v: true }, posted: { op: "eq", v: true } });

    for (let placeID of userStore.placeID) {
      let res = await outletsAPI.getOutlet({ placeID: { op: "eq", v: placeID } });
      if (isEqual(res, {})) continue;

      restaurants.value[placeID] = res;
    }
  } catch (err) {
    logError(`PastOrders.setupUserData: Failed to setup user data. ${err.toString()}`);
  }
}

const getData = async (limit) => {
  viewLoading.value = true;
  let fetchedOrders = [];
  try {
    try {
      let placeID = clientStore.getState().activeClient["data"]["placeID"];

      let ordersBatch = await ordersAPI.getOrders(placeID, limit, lastFetched.value.deliveryDate);
      if (ordersBatch.length > 0) {
        let deliveryDate;
        try {
          deliveryDate = ordersBatch[ordersBatch.length - 1].deliveryDate;
        } catch (e) {
          logError(`PastOrders.getData: Failed to get delivery date for order ${ordersBatch[ordersBatch.length - 1].orderID}`);
        }
        lastFetched.value = { deliveryDate: deliveryDate };
      }
      fetchedOrders.push(...ordersBatch);
      orders.value.push(...ordersBatch);

      await sortOrders(orders.value, sortDir.value, sortCol.value);
    } catch (err) {
      logError(`PastOrders.getData: Failed to fetch needed collections ${err.toString()}`);
    }
  } catch (e) {
    logError(`PastOrders.getData: Failed to setup component. ${e.toString()}`);
  }
  viewLoading.value = false;
  return fetchedOrders;
};

/**
 * Performs the payment flow process.
 *
 * This function extracts the necessary information from the URL and checks if the order status is confirmed.
 * If the order status is confirmed, it selects the corresponding order from the list of orders.
 * Finally, it sets the paymentSuccessful flag to true.
 *
 * @throws {Error} If the payment process fails.
 * @deprecated
 */
async function paymentFlow() {
  try {
    let url = window.location.href.split("?");

    if (url.length <= 1) return;

    url = url[1].split("&");

    if (url.length != 2) return;

    let status = url[1].split("=");
    let orderID = url[0].split("=");

    if (status[0] != "order_status" || status[1] != "confirmed") return;

    orders.value.forEach(async (order, idx) => {
      if (order.orderID == orderID[1]) {
        await selectOrder(idx);
      }
    });

    paymentSuccessful.value = true;
  } catch (e) {
    logError(`PastOrders.PaymentFlow: Failed to process successful payment`);
  }
}

/**
 * Sort columns by either ascending or descending
 */
function toggleSort(column) {
  if (sortCol.value == column) {
    sortDir.value = sortDir.value == "asc" ? "dsc" : "asc";
  } else {
    sortCol.value = column;
    sortDir.value = "asc";
  }
  sortOrders(orders.value, sortDir.value, sortCol.value);
}

async function selectOrder(orderIndex) {
  viewLoading.value = true;

  // Check Cache before fetching new list of prices
  if (stripePrices.value == null) {
    stripePrices.value = await _stripe.getStripePrices();
  }

  // Check cache before fetching new list of price groupings
  if (priceGroups.value == null) {
    priceGroups.value = await _fb.getPriceGroups();
  }

  activeOrder.value = orderIndex;
  viewLoading.value = false;
}

/**
 * Formats a given time into a specific format.
 * @param {number} time - The time to be formatted.
 * @param {string} half - Specifies whether to format only the date, only the time, or both.
 * @returns {string} - The formatted time.
 */
function formatTime(time, half) {
  const date = new Date(time);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  const hours = String(date.getHours()).padStart(2, "0");
  const minutes = String(date.getMinutes()).padStart(2, "0");
  const seconds = String(date.getSeconds()).padStart(2, "0");

  if (half == "date") {
    return `${year}-${month}-${day}`;
  }
  if (half == "time") {
    return `${hours}:${minutes}:${seconds}`;
  }
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/colors.scss";

.page-container {
  display: flex;
  flex-direction: column;
  padding: 0.25rem;
  height: 100vh;
}

.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
  margin: 1rem;
  padding-bottom: 1rem;

  span {
    display: flex;
    align-items: center;
  }
}

.t-container {
  overflow: hidden;

  table {
    height: 100%;
    display: block;

    tbody {
      height: calc(100% - 50px);
      display: block;
      overflow-y: auto;

      tr {
        display: table;
        width: 100%;
        table-layout: fixed;
      }
    }

    thead {
      display: table;
      width: calc(100% - 1em);
      table-layout: fixed;
      height: 50px;
    }

    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    th {
      cursor: pointer;
    }
  }
}

.button {
  align-self: start;
  height: 25px;
}
.modal-coloumn {
  align-items: center;
  position: relative;
  padding: 0;
  margin-bottom: 0px;
  margin: 0;
  display: none;
}

.modal-content {
  max-height: calc(100vh - 160px);
  width: calc(50% - 1em);
  border-radius: 0.5em;
}

.modal-card {
  max-height: calc(100vh - 160px);
  width: calc(70% - 1em);
  border-radius: 0.5em;
  overflow-y: auto;
  height: 100%;
}

.payment-icon-container {
  height: 25px;
  width: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.payment-icon {
  height: 100%;
}

.payment-icon-container:hover + .payment-icon-tooltip {
  display: inline-block;
}
.payment-icon-tooltip {
  position: absolute;
  bottom: 100%;
  left: 100%;
  width: 100%;
  text-align: center;
  border-radius: 6px;
  background-color: rgba($denim, 0.7);
  color: $cloud;
  display: none;
}
</style>
