import { usersAPI } from "../composables";
import { DocumentReference } from "firebase/firestore";

abstract class UserModel {
  protected _email!: string;
  protected _uid!: string;

  protected _fname!: string;
  protected _lname!: string;
  protected _client: string | undefined;

  protected _placeID!: string[];
  protected _languagePreference: string = "en";

  protected _accountTier: DocumentReference | undefined;

  protected _emailVerified: boolean = false;
  protected _disabled: boolean = true;
  protected _portalApproved: boolean = false;
  protected _authObj: { [key: string]: any } = {};
}

class UserController extends UserModel {
  static async filter(filters: { [key: string]: any } = {}, fields = undefined): Promise<User> {
    try {
      if (fields) {
        return await usersAPI.getUser(filters);
      } else {
        let user = new User();
        return user.setup(await usersAPI.getUser(filters));
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to filter user ${err.toString()}`);
    }
  }

  private setup(data: { [key: string]: any }) {
    try {
      for (let key in data) {
        if (key in this) this[key as keyof typeof this] = data[key];
      }
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to setup User ${err.toString()}`);
    }
    return this;
  }

  toString() {
    return `<User: email=${this.email} name=${this.name} />`;
  }

  get email(): string {
    return this._email;
  }
  set email(value: string) {
    this._email = value;
  }

  get uid(): string {
    return this._uid;
  }
  set uid(value: string) {
    this._uid = value;
  }

  get fname(): string {
    return this._fname;
  }
  set fname(value: string) {
    this._fname = value;
  }

  get lname(): string {
    return this._lname;
  }
  set lname(value: string) {
    this._lname = value;
  }

  get name(): string {
    return `${this.fname} ${this.lname}`;
  }

  get placeID(): string[] {
    return this._placeID;
  }
  set placeID(value: string[] | string | undefined) {
    if (value === undefined) {
      this._placeID = [];
    } else if (Array.isArray(value)) {
      this._placeID = value;
    } else {
      this._placeID = [value];
    }
  }

  get client(): string {
    return this._client ?? "";
  }
  set client(value: string | undefined) {
    this._client = value ?? "";
  }

  get languagePreference(): string {
    return this._languagePreference ?? "en";
  }
  set languagePreference(value: string | undefined) {
    this._languagePreference = value ?? "en";
  }

  get accountTier(): DocumentReference | undefined {
    return this._accountTier ?? undefined;
  }
  set accountTier(value: DocumentReference | undefined) {
    this._accountTier = value;
  }

  get emailVerified(): boolean {
    return this._emailVerified;
  }
  set emailVerified(value: boolean | undefined) {
    this._emailVerified = value ?? false;
  }

  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value: boolean | undefined) {
    this._disabled = value ?? false;
  }

  get portalApproved(): boolean {
    return this._portalApproved;
  }
  set portalApproved(value: boolean | undefined) {
    this._portalApproved = value ?? false;
  }

  get authObj(): { [key: string]: any } {
    return this._authObj;
  }
  set authObj(value: { [key: string]: any } | undefined) {
    this._authObj = value ?? {};
  }
}

class User extends UserController {
  static async getUserByEmail(email: string) {
    try {
      return (await UserController.filter({ email: { op: "eq", v: email } })) as User;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get user ${err.toString()}`);
    }
  }

  static async getUserByUID(uid: string) {
    try {
      return (await UserController.filter({ uid: { op: "eq", v: uid } })) as User;
    } catch (err: any) {
      console.log(err);
      throw new Error(`Failed to get user ${err.toString()}`);
    }
  }
}

export default User;
