import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { logError, getBasicStats } from "@/utils";

import { Outlet, Product } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'returnRates',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const returns: Ref<number> = ref(0);
const sold: Ref<number> = ref(0);
const rate: Ref<number> = ref(0);

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("ReturnRates: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let products: Product[] = await Product.getActiveProducts();
    let productMap: { [key: string]: number } = {};
    for (let product of products) {
      productMap[product.boxIDTag!] = product.containersPerCase!;
    }
    let returnRates = await props.outlet!.getReturnRates(props.weeks, props.group, productMap);
    returns.value = returnRates["returns"];
    sold.value = returnRates["sold"];

    let divZero = sold.value == 0 ? 1 : sold.value;
    rate.value = Math.round((returns.value / divZero) * 100);
  } catch (err: any) {
    console.log(err);
    failed.value = true;
    logError(`ReturnRates: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataPanel), {
    type: "return-rate",
    id: "ror",
    fontColour: "ocean",
    bgColour: "transparent",
    data: [
      {
        value: {
          percent: rate.value,
          returned: returns.value,
          sold: sold.value,
        },
        title: {
          text: _ctx.$t('analytics.return-rate'),
          colour: 'denim',
        },
      },
    ],
    unit: "%",
    loading: loading.value,
    failed: failed.value
  }, null, 8, ["data", "loading", "failed"]))
}
}

})