<template>
  <DataPanel
    type="main"
    bgColour="denim"
    :icon="[ReuseIconCloud]"
    :data="[
      {
        value: props.outlet!.returnedFrom,
        title: $t('analytics.packages-reused'),
      },
    ]"
    :loading="loading"
    :failed="failed"
  />

  <!-- <div class="triplets"> -->
  <DataPanel
    v-for="(value, name) in metrics"
    type="triplet"
    bgColour="ocean"
    :icon="[statsIcons[$t(name as string)]]"
    :data="[{ value: value, title: $t(name as string) }]"
    :key="name"
    :loading="loading"
    :failed="failed"
  />
  <!-- </div> -->
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { ReuseIconCloud, EarthIconCloud, BinIconCloud, WaterIconCloud, TransportEmissionsIconCloud } from "@/assets";
import { logError, getBasicStats } from "@/utils";

import { Outlet } from "@/models";

const statsIcons: { [key: string]: any } = {
  "GHG Emissions Diverted": EarthIconCloud,
  "Émissions de GES détournées": EarthIconCloud,
  "Waste Diverted": BinIconCloud,
  "Déchets détournés": BinIconCloud,
  "Water Savings": WaterIconCloud,
  "Eau économisée": WaterIconCloud,
  "Transport Emissions": TransportEmissionsIconCloud,
  "Émissions des transports": TransportEmissionsIconCloud,
};

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const metrics: Ref<{ [key: string]: string }> = ref({
  "analytics.ghg": "0 kg",
  "analytics.waste": "0 kg",
  "analytics.water": "0 L",
});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("Equivalencies: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    await props.outlet!.getBasicStats(props.weeks, props.group);
    metrics.value = (await getBasicStats(props.outlet!.returnedFrom!)).metrics;
  } catch (err: any) {
    failed.value = true;
    logError(`TotalReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss"></style>
