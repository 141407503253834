// Library Components
import { projectAuth } from "../firebase/config";
import type { User as FirebaseUser } from "@firebase/auth-types";

// Library Composables
import { useRouter } from "vue-router";
// Custom Components
import { fetchUserToken, logError } from "@/utils";
// Custom Composables
import { useFirebase } from "@/composables";
// State Management
import { Store, permissionStore } from "./";
import { User } from "@/models";

class UserStore extends Store {
  private _user: User | undefined;

  constructor(storeName: string) {
    super(storeName, UserStore.initialState());
  }

  static initialState() {
    return {
      fb_user: undefined,
      doc: undefined,
      uid: "",
      email: "",
      client: "",
      fname: "",
      lname: "",
      languagePreference: "",
      placeID: [],
      accountTier: undefined,
      tokenID: "",

      portalApproved: false,
      emailVerified: false,
      disabled: false,
      authObj: {},
    };
  }

  get fb_user() {
    return this.state.fb_user;
  }

  get doc() {
    return this.state.doc;
  }

  get uid() {
    return this.state.uid;
  }

  get email() {
    return this.state.email;
  }

  get client() {
    return this.state.client;
  }

  get fname() {
    return this.state.fname;
  }

  get lname() {
    return this.state.lname;
  }

  get languagePreference() {
    return this.state.languagePreference;
  }

  get placeID() {
    return this.state.placeID ?? [];
  }

  get accountTier() {
    return this.state.accountTier;
  }

  get tokenID() {
    return this.state.tokenID;
  }

  get portalApproved() {
    return this.state.portalApproved;
  }

  get emailVerified() {
    return this.state.emailVerified;
  }

  get disabled() {
    return this.state.disabled;
  }

  get authObj() {
    return this.state.authObj;
  }

  get user(): User | undefined {
    return this._user;
  }
  private set user(value: User | undefined) {
    this._user = value;
  }

  async initialize() {
    try {
      let user = projectAuth.currentUser as FirebaseUser;
      await this.setup(user);

      projectAuth.onAuthStateChanged((_user: FirebaseUser | null) => {
        this.setup(_user);
      });
    } catch (e: any) {
      logError(`userStore.initialize: Failed to initialize user store. ${e.toString()}`);
    }
  }

  async setup(user: FirebaseUser | null) {
    try {
      const router = useRouter();
      this.state.fb_user = user;

      if (this.state.fb_user != null || this.state.fb_user != undefined) {
        await this.fetchUserData();
        await this.fetchUserToken();
      } else {
        if (router) router.push("/login");
      }
    } catch (e: any) {
      console.log(e);
      logError(`userStore.setup: Failed to setup UserStore. ${e.toString()}`);
    }
  }

  async fetchUserData() {
    try {
      let userRef = await User.getUserByUID(this.state.fb_user.uid);

      this.state.doc = userRef;
      this.user = userRef;
      let userData: { [key: string]: any } = userRef;

      let fields = Object.keys(this.state);
      for (let field of fields) {
        if (field == "fb_user" || field == "doc" || field == "tokenID") continue;
        if (field in userData) this.state[field] = userData[field];
      }

      if (this.state.accountTier != undefined) {
        await permissionStore.initialize(this.state.accountTier);
      }
    } catch (e: any) {
      console.log(e);
      logError(`userStore.fetchUserData: Failed to fetch extra user data. ${e.toString()}`);
    }
  }

  async fetchUserToken() {
    try {
      if (this.state.fb_user == null) {
        return;
      }
      this.state.tokenID = await this.state.fb_user.getIdToken(true);
      // this.state.tokenID = await fetchUserToken(this.state.fb_user);
    } catch (e: any) {
      logError(`userStore.fetchUserToken: Failed to getch userToken. ${e.toString()}`);
    }
  }

  async updateLanguagePreference(lang: string) {
    try {
      const _fb = useFirebase();

      await _fb.updateUser(this.state.doc, { languagePreference: lang });
      this.state.languagePreference = lang;
    } catch (e: any) {
      logError(`userStore.updateLanguagePreference: Failed to update user langauge preference. ${e.toString()}`);
    }
  }
}

export const userStore = new UserStore("user-store");
