import { logError, fetchUserToken } from "@/utils";
import getUser from "./getUser";

// TODO looks like the allowAuthenticatedUsers is limited to IAM users... kinda makes sense but is annoying..
// So what to do - firstly just remove all references to includeAuth and the Autorization header. They aren't needed anymore.
// Discuss/research different security options?
// - more validation on the JWTs?
// - What about the public routes.. are we just limiting them to API key only. Not really the best.
async function getHeaders(includeAuth: boolean = false, includeToken: boolean = false, gcpKey: boolean = false) {
  let headers = new Headers();

  try {
    const { user } = getUser();
    let token = await fetchUserToken(user);

    if (!gcpKey) headers.append("x-api-key", process.env.VUE_APP_FRIENDLIER_API_KEY!);
    else if (gcpKey) headers.append("x-api-key", process.env.VUE_APP_GCF_API_KEY!);
    if (includeToken) headers.append("x-user-token", token);
    if (includeAuth) headers.append("Authorization", `Bearer ${token}`);
  } catch (err: any) {
    throw new Error(`Failed to get Headers ${err.toString()}`);
  }
  return headers;
}

async function request(
  url: string,
  method: "POST" | "GET" = "POST",
  body: { [key: string]: any } | undefined = undefined,
  options: { [key: string]: any } | undefined = undefined
) {
  try {
    let response = await fetch(url, {
      method: method,
      headers: await getHeaders(
        options ? options["includeAuth"] ?? false : false,
        options ? options["includeToken"] ?? false : false,
        options ? options["gcpKey"] ?? false : false
      ),
      body: JSON.stringify(body),
    });

    if (response.status == 204) {
      return {};
    }

    let responseBody = await response.json();

    if (response.status == 400) {
      throw new Error(`Bad Request ${response.status}: ${responseBody["code"]} - ${responseBody["message"]}`);
    }
    if (response.status == 401) {
      throw new Error(`Unauthorized Request ${response.status}: ${responseBody["code"]} - ${responseBody["message"]}`);
    }
    if (response.status == 403) {
      throw new Error(`Access Denied ${response.status}: ${responseBody["code"]} - ${responseBody["message"]}`);
    }
    if (response.status == 404) {
      throw new Error(`Route not found ${response.status}: ${responseBody["code"]} - ${responseBody["message"]}`);
    }
    if (response.status == 500) {
      throw new Error(`Server Error ${response.status} - ${responseBody["message"]}`);
    }

    return responseBody;
  } catch (e: any) {
    console.log(e);
    let pieces = url.split("/");
    logError(`useAPI.request: Failed to make request. ${pieces[pieces.length - 1]} . ${e.toString()}`);
  }
}

const useAPI = () => {
  return {
    request,
  };
};

export default useAPI;
