<template>
  <FriendlierDoughnutChart id="sold-returned-cumulative" title="Group Returns" :dataset="groupReturns" :loading="loading" />
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { FriendlierDoughnutChart } from "@/components";
import { logError } from "@/utils";

import { Outlet } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const groupReturns: Ref<{ [key: string]: any }[]> = ref([]);

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("OutletReturns: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);

async function setup() {
  loading.value = true;
  try {
    await props.outlet!.getGroupReturns(props.weeks);

    let dataset = [];
    for (const [key, val] of Object.entries(props.outlet!.groupReturns!)) {
      dataset.push({ label: val["name"], value: val["returns"] });
    }

    groupReturns.value = dataset;
  } catch (err: any) {
    failed.value = true;
    logError(`OutletReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss"></style>
