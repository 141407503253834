import { useAPI } from "@/composables";

/**
 * Calculates the order totals for a given outlet and quantities.
 *
 * @param {string} outlet - The outlet for which to calculate the order totals.
 * @param {{[key: string]: any}} quantities - The quantities of the orders.
 * @throws {Error} - If there is an error calculating the order totals.
 */
async function calculateOrderTotals(outlet, quantities) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/calcTotals`,
      "POST",
      { outlet: outlet, orderQuantities: quantities },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to calculate order totals. ${err.toString()}`);
  }
}

/**
 * Places an order with the specified details.
 *
 * @param {string} outletID - The ID of the outlet where the order is being placed.
 * @param {number} deliveryDate - The delivery date of the order.
 * @param {Object} orderQuantities - The quantities of each item in the order.
 * @param {string} [notes] - Optional notes for the order.
 * @returns {Promise<Object>} A promise that resolves to the response data from the server.
 * @throws {Error} If there is an error while placing the order.
 */
async function placeOrder(
  outlet,
  deliveryDate,
  orderQuantities,
  notes = undefined) {
  try {
    let body = {
      outlet,
      deliveryDate,
      orderQuantities
    }
    if (notes) {
      body.notes = notes;
    }

    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/placeOrder`,
      "POST",
      body,
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to calculate order totals. ${err.toString()}`);
  }
}

/**
 * Creates a new order.
 *
 * @param {string} outlet - The ID of the outlet where the order is placed.
 * @param {string} deliveryDate - The delivery date of the order.
 * @param {Array<number>} quantities - An array of quantities for each item in the order.
 * @param {string} [notes] - Optional notes for the order.
 * @returns {Promise<any>} - A promise that resolves to the response data from the server.
 * @throws {Error} - If there is an error creating the order.
 */
async function createOrder(outlet, deliveryDate, quantities, notes = undefined) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/createOrder`,
      "POST",
      {
        outletID: outlet,
        orderDetails: quantities,
        deliveryDate: deliveryDate,
        notes: notes
      },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to create order. ${err.toString()}`);
  }
}

/**
 * Retrieves orders from the server for a specific outlet.
 *
 * @param {string} outlet - The ID of the outlet to retrieve orders for.
 * @returns {Promise<Array>} - A promise that resolves to an array of orders.
 * @throws {Error} - If there is an error retrieving the orders.
 */
async function getOrders(outlet, limit, startAfter = null) {
  try {
    let payload = {
      filters: { outlet: { "op": "eq", "v": outlet }, state: { "op": "nin", "v": ["created","creating"] } },
      orderBy: [ { field: "deliveryDate", dir: "desc" } ],
      pageLimit: limit
    }
    if (startAfter) {
      payload.startAfter = { deliveryDate: startAfter };
    }
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/orders`,
      "POST",
      payload,
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to get orders. ${err.toString()}`);
  }
}

/**
 * Retrieves an order by its ID.
 *
 * @param {string} orderID - The ID of the order to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved order data.
 * @throws {Error} - If there was an error retrieving the order.
 */
async function getOrder(orderID) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/order`,
      "POST",
      { filters: { orderID: { "op": "eq", "v": orderID } } },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to get order. ${err.toString()}`);
  }
}


/**
 * Sets up a credit card for the given order and outlet.
 * @param {Object} order - The order object.
 * @param {string} outlet - The outlet identifier.
 * @returns {Promise<Object>} - A promise that resolves to the response data.
 * @throws {Error} - If there is an error setting up the credit card.
 */
async function setupCreditCard(order, outlet) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/creditCardSetup`,
      "POST",
      { 
        order: order,
        outlet: outlet,
      },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to setup credit card. ${err.toString()}`);
  }
}

/**
 * Deletes an order with the specified order ID.
 * @param {string} orderID - The ID of the order to be deleted.
 * @returns {Promise<any>} - A promise that resolves to the response data from the server.
 * @throws {Error} - If the deletion of the order fails.
 */
async function deleteOrder(orderID) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/deleteOrder`,
      "POST",
      { orderID: orderID },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to delete order. ${err.toString()}`);
  }
}

/**
 * Finalizes an order by sending a request to the server.
 * @param {string} orderID - The ID of the order to be finalized.
 * @returns {Promise<any>} - A promise that resolves to the response data from the server.
 * @throws {Error} - If the request to finalize the order fails.
 */
async function finalizeOrder(orderID) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/finalizeOrder`,
      "POST",
      { order: orderID },
      { includeToken: true }
    );

    return response.data;
  } catch (err) {
    throw new Error(`Failed to finalize order. ${err.toString()}`);
  }
}

async function getDeliveryDate(outletID) {
  try {
    let response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/getDeliveryDate`,
      "POST",
      { outletID: outletID },
      { includeToken: true, includeAuth: true }
    );

    return new Date(response.data);
  } catch (err) {
    throw new Error(`Failed to get delivery date. ${err.toString()}`);
  }
}

const ordersAPI = {
  calculateOrderTotals,
  placeOrder,
  createOrder,
  getOrders,
  getOrder,
  setupCreditCard,
  deleteOrder,
  finalizeOrder,
  getDeliveryDate,
};

export default ordersAPI;