import { logError } from "@/utils";
import { useAPI } from "@/composables";

async function getUser(filters: { [key: string]: any }, fields: string[] | undefined = undefined) {
  try {
    let body: { [key: string]: any } = {
      filters: filters,
    };
    if (fields) {
      body.fields = fields;
    }

    const response = await useAPI().request(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/user`, "POST", body, {
      includeAuth: true,
      includeToken: true,
    });

    return response.data[0];
  } catch (err: any) {
    console.log(err);
    logError(`usersAPI.getUser: Failed to get user. ${err.toString()}`);
    throw new Error(`Failed to get user ${err.toString()}`);
  }
}

const usersAPI = {
  getUser,
};

export default usersAPI;
