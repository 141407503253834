import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { FriendlierLineChart } from "@/components";
import { logError } from "@/utils";

import { Outlet, Product } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'returnsBreakdown',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const returnsBreakdown: Ref<{ [key: string]: any }> = ref({});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("OutletReturns: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    let products: Product[] = await Product.getActiveProducts();
    let productMap: { [key: string]: number } = {};
    for (let product of products) {
      productMap[product.boxIDTag!] = product.containersPerCase!;
    }

    let test = await props.outlet!.getReturnsBreakdown(props.weeks, props.group, productMap);
    returnsBreakdown.value = test;
  } catch (err: any) {
    failed.value = true;
    logError(`OutletReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(FriendlierLineChart), {
    id: "returns-line",
    labels: [''],
    dataset: returnsBreakdown.value,
    defaultKey: "total",
    title: "Sold* vs Returned",
    loading: loading.value
  }, null, 8, ["dataset", "loading"]))
}
}

})