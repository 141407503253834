import { logError } from "@/utils";
import { useAPI } from "@/composables";

async function getOutlet(filters: { [key: string]: any }, fields: string[] | undefined = undefined) {
  try {
    let body: { [key: string]: any } = {
      filters: filters,
    };
    if (fields) {
      body.fields = fields;
    }

    const response = await useAPI().request(`${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/outlet`, "POST", body, {
      includeAuth: true,
      includeToken: true,
    });

    return response.data;
  } catch (err: any) {
    console.log(err);
    logError(`outletsAPI.getOutlet: Failed to get outlet. ${err.toString()}`);
    throw new Error(`Failed to get outlet ${err.toString()}`);
  }
}

async function getOutlets(filters: { [key: string]: any }, fields: string[] | undefined = undefined) {
  try {
    const response = await useAPI().request(
      `${process.env.VUE_APP_FIREBASE_FUNCTIONS_BASE_URL}/outlets`,
      "POST",
      {
        filters: filters,
        fields: fields,
      },
      {
        includeAuth: true,
        includeToken: true,
      }
    );

    return response.data;
  } catch (err: any) {
    console.log(err);
    logError(`outletsAPI.getOutlets: Failed to get outlet. ${err.toString()}`);
    throw new Error(`Failed to get outlet ${err.toString()}`);
  }
}

const outletsAPI = {
  getOutlet,
  getOutlets,
};

export default outletsAPI;
