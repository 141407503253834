import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "data-delim" }
const _hoisted_2 = { class: "text" }

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { BowlingIconCloud, TreeIconCloud, BottleIconCloud } from "@/assets";
import { logError, getBasicStats } from "@/utils";

import { Outlet } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'equivalencies',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const equivalencies: Ref<{ [key: string]: number }> = ref({
  "analytics.bowling": 0,
  "analytics.trees": 0,
  "analytics.bottles": 0,
});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("Equivalencies: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let returns = await props.outlet!.getBasicStats(props.weeks, props.group);
    equivalencies.value = (await getBasicStats(returns)).equivalencies;
  } catch (err: any) {
    failed.value = true;
    logError(`Equivalencies: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_unref(DataPanel), {
    type: "full",
    bgColour: "denim",
    icon: [_unref(BowlingIconCloud), null, _unref(TreeIconCloud), null, _unref(BottleIconCloud)],
    data: 
      Object.keys(equivalencies.value).map((key) => ({
        title: _ctx.$t(key),
        value: equivalencies.value[key],
      }))
    ,
    delim: true,
    title: _ctx.$t('analytics.thats-like'),
    loading: loading.value,
    failed: failed.value
  }, {
    default: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _cache[0] || (_cache[0] = _createElementVNode("div", { class: "divider" }, null, -1)),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("or")), 1),
        _cache[1] || (_cache[1] = _createElementVNode("div", { class: "divider" }, null, -1))
      ])
    ]),
    _: 1
  }, 8, ["icon", "data", "title", "loading", "failed"]))
}
}

})