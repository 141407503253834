<template>
  <DataPanel
    type="full"
    bgColour="denim"
    :icon="[BowlingIconCloud, null, TreeIconCloud, null, BottleIconCloud]"
    :data="
      Object.keys(equivalencies).map((key) => ({
        title: $t(key),
        value: equivalencies[key],
      }))
    "
    :delim="true"
    :title="$t('analytics.thats-like')"
    :loading="loading"
    :failed="failed"
  >
    <span class="data-delim">
      <div class="divider" />
      <p class="text">{{ $t("or") }}</p>
      <div class="divider" />
    </span>
  </DataPanel>
</template>
<script setup lang="ts">
import { defineProps, onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { BowlingIconCloud, TreeIconCloud, BottleIconCloud } from "@/assets";
import { logError, getBasicStats } from "@/utils";

import { Outlet } from "@/models";

const props = defineProps({
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const equivalencies: Ref<{ [key: string]: number }> = ref({
  "analytics.bowling": 0,
  "analytics.trees": 0,
  "analytics.bottles": 0,
});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("Equivalencies: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;

  try {
    let returns = await props.outlet!.getBasicStats(props.weeks, props.group);
    equivalencies.value = (await getBasicStats(returns)).equivalencies;
  } catch (err: any) {
    failed.value = true;
    logError(`Equivalencies: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}
</script>
<style scoped lang="scss">
@import "@/assets/scss/colors.scss";

.data-delim {
  display: flex;
  flex-direction: column;
  align-self: center;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  margin: auto;
  height: fit-content;

  .divider {
    flex-grow: 2;
    background-color: $cloud;
    min-height: 20px;
    min-width: 1px;
  }

  .text {
    color: $cloud;
    flex-grow: 1;
  }
}

@media screen and (max-width: 768px) {
  .data-delim {
    display: none;
  }
}
</style>
