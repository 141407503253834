import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { DataPanel } from "@/components";
import { ReuseIconCloud, EarthIconCloud, BinIconCloud, WaterIconCloud, TransportEmissionsIconCloud } from "@/assets";
import { logError, getBasicStats } from "@/utils";

import { Outlet } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'totalReturns',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const statsIcons: { [key: string]: any } = {
  "GHG Emissions Diverted": EarthIconCloud,
  "Émissions de GES détournées": EarthIconCloud,
  "Waste Diverted": BinIconCloud,
  "Déchets détournés": BinIconCloud,
  "Water Savings": WaterIconCloud,
  "Eau économisée": WaterIconCloud,
  "Transport Emissions": TransportEmissionsIconCloud,
  "Émissions des transports": TransportEmissionsIconCloud,
};

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const metrics: Ref<{ [key: string]: string }> = ref({
  "analytics.ghg": "0 kg",
  "analytics.waste": "0 kg",
  "analytics.water": "0 L",
});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("Equivalencies: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    await props.outlet!.getBasicStats(props.weeks, props.group);
    metrics.value = (await getBasicStats(props.outlet!.returnedFrom!)).metrics;
  } catch (err: any) {
    failed.value = true;
    logError(`TotalReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_unref(DataPanel), {
      type: "main",
      bgColour: "denim",
      icon: [_unref(ReuseIconCloud)],
      data: [
      {
        value: props.outlet!.returnedFrom,
        title: _ctx.$t('analytics.packages-reused'),
      },
    ],
      loading: loading.value,
      failed: failed.value
    }, null, 8, ["icon", "data", "loading", "failed"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(metrics.value, (value, name) => {
      return (_openBlock(), _createBlock(_unref(DataPanel), {
        type: "triplet",
        bgColour: "ocean",
        icon: [statsIcons[_ctx.$t(name as string)]],
        data: [{ value: value, title: _ctx.$t(name as string) }],
        key: name,
        loading: loading.value,
        failed: failed.value
      }, null, 8, ["icon", "data", "loading", "failed"]))
    }), 128))
  ], 64))
}
}

})