import { reactive, readonly } from "vue";

export class Store {
  constructor(storeName: string, initialState: { [key: string]: any }) {
    this.storeName = storeName;
    this.state = reactive(initialState);
  }

  storeName;
  state;

  getState() {
    return readonly(this.state);
  }
}
