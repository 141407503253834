import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "table-container"
}
const _hoisted_2 = { class: "table" }
const _hoisted_3 = {
  key: 1,
  class: "no-orders"
}
const _hoisted_4 = {
  key: 1,
  class: "loading"
}
const _hoisted_5 = {
  key: 2,
  class: "failed"
}
const _hoisted_6 = { class: "danger-icon" }
const _hoisted_7 = ["src"]

import { onMounted, ref, watch } from "vue";
import type { Ref } from "vue";

import { FriendlierLineChart, Loading } from "@/components";
import { logError } from "@/utils";
import { DangerIconBlack } from "@/assets";

import { Outlet, Product } from "@/models";


export default /*@__PURE__*/_defineComponent({
  __name: 'ordersBreakdown',
  props: {
  outlet: Outlet,
  weeks: {
    type: Array<string>,
    default: [],
    required: false,
  },
  group: {
    type: Boolean,
    default: false,
    required: false,
  },
},
  setup(__props) {

const props = __props;

const loading: Ref<boolean> = ref(true);
const failed: Ref<boolean> = ref(false);

const ordersBreakdown: Ref<{ [key: string]: any }> = ref({});

onMounted(() => {
  if (!props.outlet) {
    failed.value = true;
    loading.value = false;
    logError("ordersBreakdown: Outlet is required");
  } else {
    setup();
  }
});

watch(() => props.weeks, setup);
watch(() => props.group, setup);

async function setup() {
  loading.value = true;
  try {
    ordersBreakdown.value = await props.outlet!.getOrdersBreakdown(props.weeks, props.group);
  } catch (err: any) {
    failed.value = true;
    logError(`OutletReturns: Failed to fetch new data, ${err.toString()}`);
  }
  loading.value = false;
}

return (_ctx: any,_cache: any) => {
  return (!loading.value && !failed.value)
    ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
        (ordersBreakdown.value.keys && ordersBreakdown.value.keys.size > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("table", null, [
                  _createElementVNode("thead", null, [
                    _createElementVNode("tr", null, [
                      _cache[0] || (_cache[0] = _createElementVNode("th", null, "Week", -1)),
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ordersBreakdown.value.keys, (key) => {
                        return (_openBlock(), _createElementBlock("td", { key: key }, _toDisplayString(key), 1))
                      }), 128))
                    ])
                  ]),
                  _createElementVNode("tbody", null, [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ordersBreakdown.value.values, (value) => {
                      return (_openBlock(), _createElementBlock("tr", {
                        key: value.week
                      }, [
                        _createElementVNode("th", null, _toDisplayString(value.week), 1),
                        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(ordersBreakdown.value.keys, (key) => {
                          return (_openBlock(), _createElementBlock("td", {
                            key: `${value.week}-${key}`
                          }, _toDisplayString(key in value ? value[key] : 0), 1))
                        }), 128))
                      ]))
                    }), 128)),
                    _cache[1] || (_cache[1] = _createElementVNode("tr", null, [
                      _createElementVNode("th")
                    ], -1))
                  ])
                ])
              ])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, "No Orders made within time period"))
      ], 64))
    : (loading.value && !failed.value)
      ? (_openBlock(), _createElementBlock("span", _hoisted_4, [
          _createVNode(_unref(Loading))
        ]))
      : (!loading.value && failed.value)
        ? (_openBlock(), _createElementBlock("span", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createElementVNode("img", { src: _unref(DangerIconBlack) }, null, 8, _hoisted_7)
            ]),
            _cache[2] || (_cache[2] = _createElementVNode("p", { class: "is-cloud" }, "Failed to Load", -1))
          ]))
        : _createCommentVNode("", true)
}
}

})